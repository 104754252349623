import React from "react"
import { graphql, StaticQuery } from "gatsby"
import newsButton from '../assets/images/news-button.svg'

export default function MediumArticles() {
  const MEDIUM_CDN = "https://cdn-images-1.medium.com/max/800"
  const MEDIUM_URL = "https://medium.com"

  return (
    <StaticQuery
      query={graphql`
        query Medium {
          allMediumPost(limit: 3) {
            edges {
              node {
                id
                title
                uniqueSlug
                updatedAt(formatString: "MMM DD YYYY")
                createdAt(formatString: "MMM DD YYYY")
                firstPublishedAt(formatString: "MMM DD YYYY")
                virtuals {
                  previewImage {
                    imageId
                  }
                }
              }
            }
          }
        }
      `}
      render={data => (
        <div className="row blog-article-list container">
          {data.allMediumPost.edges.map(post => (
            <div className="col-xs-10 col-md-6 col-lg-4 item justify-content-center" key={post.node.id}>
              <a href={`${MEDIUM_URL}/silta-finance/${post.node.uniqueSlug}`} className="article-block-link" target="_blank" rel="noreferrer">
                <div className="img-wrapper-16-9">
                  <img src={`${MEDIUM_CDN}/${post.node.virtuals.previewImage.imageId}`} className="article-img" alt="Article image"/>
                </div>
                <h4 className="article-title">
                  {post.node.title}
                </h4>
                <div className="item-footer">
                  <div className="date-container">
                  <p className="date-title">Published on</p>
                  <p className="date">{post.node.firstPublishedAt}</p>
                  </div>
                  <div className="button-container">
                  <img src={newsButton} className="footer-button" alt="go to blog" />
                  </div>
                </div>
              </a>
            </div>
          ))}
        </div>
      )}
    />
  )
}