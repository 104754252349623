import React from 'react'
import TechBullion from '../assets/images/seen-on/logo_TechBullion.svg'
import yahooLogo from '../assets/images/seen-on/logo_yahoo-finance.svg'
import blockleadersLogo from '../assets/images/seen-on/logo_blockleaders.svg'
import CityAm from '../assets/images/seen-on/logo_cityAM.svg'


const SeenOn = () => {
    return (
        <section className="section-seen">
          <div className="container">
            <div className="section-header">
                <div className='d-flex'>
                <h2 className="section-title"> <span className='title-dot'/> AS SEEN ON</h2>
                </div>
            </div>
            <div className='seen-on-logos'>
                {/* <a href='https://techbullion.com/interview-ben-sheppard-co-founder-of-silta-finance/' target='_blank' rel="noreferrer"> */}
                  <img src={TechBullion} alt="Tech Bullion logo" className='seen-on_logo'/>
                {/* </a> */}
                {/* <a href='https://uk.finance.yahoo.com/news/coinsilium-group-limited-signing-eca-060025562.html' target='_blank' rel="noreferrer"> */}
                  <img src={yahooLogo} alt="Yahoo Finance logo" className='seen-on_logo' />
                {/* </a> */}
                {/* <a href='https://www.blockleaders.io/news/silta-partners-with-bluejay-finance-to-explore-using-locally-denominated-stablecoins-in-loan-pools' target='_blank' rel="noreferrer"> */}
                <img src={blockleadersLogo} alt="Blockleaders logo" className='seen-on_logo d-md-none d-xs-block d-lg-block' />
                {/* </a> */}
                {/* <a href='https://www.cityam.com/boots-just-doing-what-boots-does-best-inside-the-life-of-a-crypto-entrepreneur/' target='_blank' rel="noreferrer"> */}
                  <img src={CityAm} alt="City AM logo" className='seen-on_logo' />
                {/* </a> */}
            </div>
          </div>
        </section>
      )
}

export default SeenOn