import React from 'react'
import MediumArticles from '../components/mediumArticles'
import externalIcon from '../assets/images/external-link-black.svg'
import { Link } from 'gatsby'


const NewsAndBlog = () => {
  return (
    <section className="section-blog">
      <div className="">
        <div className="container section-header d-flex space-between align-items-center">
            <div className=''>
            <h2 className="section-title"> NEWS AND BLOG</h2>
            </div>
            <div className='all-posts-container-first'>
            <a href="https://medium.com/silta-finance" target='_blank' rel="noreferrer" className='all-posts'>VIEW ALL POSTS<img src={externalIcon} alt="external link" className='external-link-icon' /></a>
            </div>
        </div>
        <MediumArticles/>
          <div className='all-posts-container'>
            <Link to="/" className='all-posts'>VIEW ALL POSTS<img src={externalIcon} alt="external link" className='external-link-icon' /></Link>
          </div>
      </div>
    </section>
  )
}

export default NewsAndBlog