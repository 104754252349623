import React from 'react'
import Main from './main'


const LeadSection = () => {

  return (
      <Main
        title="Silta accelerates the due diligence process and makes financing of impactful infrastructure faster and easier."
        text="Silta conducts a due diligence process, assesses borrowers' proposed projects for viability and impact, and assigns a Silta Score. Silta is the meeting place where financiers meet with borrowers to find and secure investment."
      />
  )
}
export default LeadSection