import React from 'react'
import TxLogo from '../assets/images/partner-logos/tx-logo.svg'
import StreamrLogo from '../assets/images/partner-logos/streamr-logo.svg'
import FrontierLogo from '../assets/images/partner-logos/frontier-logo.svg'
import SangreLogo from '../assets/images/partner-logos/sangre-logo.svg'
import BesixLogo from '../assets/images/partner-logos/besix-logo.svg'
import PCLogo from '../assets/images/partner-logos/pc-logo.svg'
import GHDLogo from '../assets/images/partner-logos/ghd-logo.svg'
import AvapaLogo from '../assets/images/partner-logos/avapa-energy-logo.svg'
import CleanestCharge from '../assets/images/partner-logos/cleanest-charge-logo.svg'
import Valfry from '../assets/images/partner-logos/valfry-logo.png'
import Api3Logo from '../assets/images/partner-logos/API3-logo.png'
import BlueJay from '../assets/images/partner-logos/bluejay_black.png'
import MadSkullz from '../assets/images/partner-logos/MadSkullz-black.png'
import EVA from '../assets/images/partner-logos/eva-logo.png'
import Cerulean from '../assets/images/partner-logos/cerulean-logo.png'
import Royal from '../assets/images/partner-logos/Royal_Eijkelkamp_black.png'
import Centrifuge from '../assets/images/partner-logos/centifuge-logo.svg'
import GreenAntz from '../assets/images/partner-logos/GreenAntzLogo.png'
import Unergy from '../assets/images/partner-logos/unergyLogo.svg'

// INVESTORS
import AzKapitalLogo from '../assets/images/partner-logos/az-kapital-logo.svg'
import PalarCapitalLogo from '../assets/images/partner-logos/palar-capital-logo.svg'
import Coinsilium from '../assets/images/partner-logos/coinsilium-logo.png'
import Chapter1Logo from '../assets/images/partner-logos/chapter1-logo.svg'
import Concave from '../assets/images/partner-logos/concave-logo.svg'

const PartnersAndInvestors = () => {
  return (
      <section className="section-partners">
      <div>
        <div className="section-partners-content">
          <h2 className="section-title">
            ECOSYSTEM</h2>
          <div>
          <ul className="partner-logo-list">
          <li className="item">
              <a href="https://centrifuge.io/" target="_blank" rel="noreferrer">
                <img src={Centrifuge} className="centrifuge-logo" alt="Centrifuge logo"/>
              </a>
            </li>
            <li className="item">
              <a href="https://medium.com/silta-finance/news-silta-finance-partners-with-besix-entering-the-mena-region-bed7a9a18353" target="_blank" rel="noreferrer">
                <img src={BesixLogo} alt="Besix logo"/>
              </a>
            </li>
            <li className="item">
              <a href="https://medium.com/silta-finance/news-silta-partners-with-psychology-cares-aims-to-enable-psychology-and-neuroscience-research-82493cd2b224" target="_blank" rel="noreferrer">
                <img src={PCLogo} className="pc-logo" alt="Psychology Cares logo"/>
              </a>
            </li>
            <li className="item">
              <a href="https://medium.com/silta-finance/news-ghd-partners-with-silta-to-help-develop-new-defi-lending-solution-for-infrastructure-e3ee12994b7a" target="_blank" rel="noreferrer">
                <img src={GHDLogo} className="ghd" alt="GHD logo"/>
              </a>
            </li>
            <li className="item">
              <a href="https://medium.com/silta-finance/news-avapa-energy-partners-with-silta-finance-to-explore-defi-for-the-future-funding-of-solar-2896d9524ff8" target="_blank" rel="noreferrer">
                <img src={AvapaLogo} className='avapa-logo' alt="Avapa logo"/>
              </a>
            </li>
            <li className="item">
              <a href="https://medium.com/silta-finance/cleanestcharge-partners-with-silta-finance-looking-for-defi-to-supercharge-its-mission-to-scale-d0fc4cba2522" target="_blank" rel="noreferrer">
                <img src={CleanestCharge} className='cleanest-charge' alt="Cleanest Charge logo"/>
              </a>
            </li>
            <li className="item">
              <a href="https://medium.com/silta-finance/news-silta-partners-with-api3-to-use-oracle-solution-for-price-reference-data-to-loan-pools-aefd89e705b8" target="_blank" rel="noreferrer">
                <img src={Api3Logo} className='api3-logo' alt="API 3 logo"/>
              </a>
            </li>
            <li className="item">
              <a href="https://medium.com/silta-finance/silta-partners-with-bluejay-finance-to-explore-using-locally-denominated-stablecoins-in-loan-pools-9f9bb3dbe881" target="_blank" rel="noreferrer">
                <img src={BlueJay} className='bluejay-logo' alt="Bluejay logo"/>
              </a>
            </li>
            <li className="item">
              <a href="https://medium.com/silta-finance/valfry-and-silta-finance-sign-a-partnership-to-channel-defi-towards-a-collective-green-energy-16740524bbb6" target="_blank" rel="noreferrer">
                <img src={Valfry} className='valfry-logo' alt="Valfry logo"/>
              </a>
            </li>
            <li className="item">
              <a href="https://medium.com/silta-finance/silta-finance-madskullz-community-partnership-b50c666b9b9c" target="_blank" rel="noreferrer">
                <img src={MadSkullz} className='madskullz-logo' alt="Mad Skullz logo"/>
              </a>
            </li>
            <li className="item">
              <a href="https://medium.com/silta-finance/eva-partners-with-silta-finance-in-aims-to-take-highways-to-the-skies-with-the-help-of-defi-b412656d7c43" target="_blank" rel="noreferrer">
                <img src={EVA} className='EVA-logo' alt="EVA logo"/>
              </a>
            </li>
            <li className="item">
              <a href="https://medium.com/silta-finance/news-silta-partners-with-green-antz-to-direct-finance-towards-tackling-and-upcycling-plastic-waste-b73ca1273048" target="_blank" rel="noreferrer">
                <img src={GreenAntz} alt="Green Antz logo" className=''/>
              </a>
            </li>
            <li className="item">
              <a href="https://medium.com/silta-finance/news-silta-and-royal-eijkelkamp-partner-in-aims-to-channel-financing-towards-nature-inclusive-e2c1a9732a2b" target="_blank" rel="noreferrer">
                <img src={Royal} alt="Royal Eijkelkamp logo" className='royal-logo'/>
              </a>
            </li>
            <li className="item">
              <a href="https://tx.company/" target="_blank" rel="noreferrer">
                <img src={TxLogo} alt="TX logo"/>
              </a>
            </li>

            <li className="item">
              <a href="https://www.linkedin.com/company/frontier-law-and-advisory/" target="_blank" rel="noreferrer">
                <img src={FrontierLogo} className="frontier-logo" alt="Frontier logo"/>
              </a>
            </li>
            <li className="item">
              <a href="https://www.sangre.fi" target="_blank" rel="noreferrer">
                <img src={SangreLogo} alt="Sangre logo" className='sangre-logo'/>
              </a>
            </li>
            <li className="item">
              <a href="https://unergy.io/" target="_blank" rel="noreferrer">
                <img src={Unergy} alt="Unergy logo"/>
              </a>
            </li>
          </ul>
        </div>
        <div>
          <h2 className="section-title investors">BACKED BY</h2>
          <ul className="partner-logo-list justify-content-center">
          <li className="item">
              <span className="no-link">
                <img src={PalarCapitalLogo} className='palar-capital-logo' alt="Palar Capital logo"/>
              </span>
            </li>
            <li className="item">
              <a href="https://medium.com/silta-finance/news-coinsilium-enters-eca-with-silta-finance-and-joins-as-advisor-to-the-project-fafb53056147" target="_blank" rel="noreferrer">
                <img src={Coinsilium} className='coinsilium-logo' alt="Coinsilium logo"/>
              </a>
            </li>
            <li className="item">
              <a href="https://medium.com/silta-finance/news-silta-receives-backing-from-cerulean-ventures-b3d6aa21fe44" target="_blank" rel="noreferrer">
                <img src={Cerulean} alt="Cerulean logo" className='cerulean-logo'/>
              </a>
            </li>
            <li className="item">
              <a href="https://az-kapital.com/" target="_blank" rel="noreferrer">
                <img src={AzKapitalLogo} alt="AZ Kapital logo"/>
              </a>
            </li>
            <li className="item">
              <a href="https://chapterone.com/" target="_blank" rel="noreferrer">
                <img src={Chapter1Logo} alt="Chapter 1 logo"/>
              </a>
            </li>
            <li className="item">
              <a href="https://streamr.network/" target="_blank" rel="noreferrer">
                <img src={StreamrLogo} className="streamr-logo" alt="Streamr logo"/>
              </a>
            </li>
            <li className="item">
              <a href="https://concave.lol/" target="_blank" rel="noreferrer">
                <img src={Concave} alt="Concave logo"/>
              </a>
            </li>
            </ul>
            </div>
        </div>
      </div>
    </section>
  )
}

export default PartnersAndInvestors